export default {
  DiseasesControl: {
    header: {
      planning_title: 'Planejamento de Fungicidas',
      compare_programs: 'Comparar programas',
      add_program: 'Novo programa',
      filter_by: 'Filtre por',
      select_field: 'Selecione talhão',
      search_field: 'Procure o talhão',
      select_variety: 'Selecione genética',
      search_variety: 'Procure a genética',
      period: 'Período',
    },
    home: {
      without_field_text:
        'Cadastre seus talhões para poder planejar seus fungicidas',
      without_field_title: 'Você ainda não possui talhões cadastrados',
      without_sowing_text:
        'A cultura, genética e data estimada são necessárias para nosso algoritmo realizar os cálculos de eficácias com base na <b>sua realidade</b>',
      without_sowing_title:
        'Você ainda não possui semeaduras planejadas para este talhão',
      without_program_text: 'Defina a data da semeadura e a pressão de doenças',
      without_program_title: '1º Passo: configure seu primeiro programa',
    },
    compare_home: {
      farm: 'Fazenda',
      search_farm: 'Procure a fazenda',
      variety: 'Cultivar',
      search_variety: 'Procure a cultivar',
      program: 'Programa',
      search_program: 'Procure o programa',
      add: 'Adicionar',
    },
    add_program_modal: {
      create_program: 'Criar programa',
      save: 'Salvar',
      new_program: 'Novo programa',
      edit_program: 'Editar programa',
      program_name: 'Nome do programa',
      insert_program_name: 'Insira um nome para o programa',
      estimated_sowing_hint: 'Período que você pretende semear',
      estimated_sowing_date: 'Data da semeadura estimada',
      select_date: 'Selecionar data',
      expected_pressure: 'Pressão esperada',
      high: 'Alta',
      medium: 'Média',
      low: 'Baixa',
      diseases: 'Doenças',
      diseases_description:
        'Ao marcar "Sim" para Mofo Branco e/ou Mela, podemos considerar aplicações específicas com comportamento específico em relação as demais.',
      white_mold_control: 'Gostaria de controlar Mofo Branco?',
      aerial_web_blight_control: 'Gostaria de controlar Mela?',
      no: 'Não',
      yes: 'Sim',
      successful_program_created: 'Programa registrado com sucesso',
      date_not_recommended: 'Data não recomendada para sua região',
      registered_sowing_date: 'Data de semeadura já cadastrada',
      date_selection_warning:
        'Ao escolher uma data com planejamento de semeadura, este será associado ao programa. Caso a data não possua um planejamento de semeadura, um novo planejamento será criado e associado ao programa.',
      edit_date_selection_warning:
        'Em ambos casos, o atual planejamento de semeadura referente ao programa será desvinculado.',
    },
    program_item: {
      spraying: 'aplicação',
      sprayings: 'aplicações',
      sowing_on: 'Semeadura em',
      pressure: 'pressão',
      program_cost: 'Custo do programa',
      waiting_first_spraying: 'Aguardando 1ª aplicação',
      execute_in_season: 'Executar em safra',
      suggested_sprayings_quantity: 'Número de aplicações sugeridas',
      to: 'a',
      white_mold_spraying: '1 para mofo branco',
      insert_spraying: 'Inserir {0} aplicação',
      configure_program: 'Configurar programa',
      not_configured: 'Não configurado',
      high: 'Alta',
      medium: 'Média',
      low: 'Baixa',
      edit: 'Editar',
      copy_to: 'Copiar para',
      duplicate: 'Duplicar',
      compare_program: 'Comparar programa',
      print: 'Imprimir',
      delete: 'Excluir',
      select_field_description:
        'Selecione abaixo os talhões que você deseja fazer uma cópia dessa semeadura',
      copy_program: 'Copiar programa',
      successfully_copy_program:
        'Programa copiado para os talhões selecionados',
      successfully_duplicate_program: 'Programa duplicado para o talhão',
      delete_program_title: 'Tem certeza que deseja excluir programa?',
      delete_program_text:
        'Todos os dados contidos nesse programa serão perdidos e essa ação não poderá ser desfeita',
    },
    program_spraying_item: {
      spraying: 'aplicação',
      products: 'Produtos',
      more_products: 'Mais {0} produtos',
      efficacies: 'Eficácias',
      edit: 'Editar',
      delete: 'Excluir',
    },
    execute_program_modal: {
      execute_program: 'Executar programa',
      execute_in_season: 'Executar programa em safra',
      fields_to_execute_warning:
        'Selecione os talhões que deseja executar o programa',
      sowings_to_execute:
        'Selecione as semeaduras que deseja executar o programa',
      choose_sowing: 'Escolher semeadura',
      new_sowing: 'Nova semeadura',
      loading: 'Carregando',
      disease: 'fungicida',
      program_of: 'Programa de',
      program_objective: 'O que você deseja fazer com o programa?',
      choose_sowing_info:
        'Nesta opção de <b>escolher semeadura</b>, você irá associar o programa escolhido a semeaduras já executadas em safra. Caso não tenha nenhuma, opte pela opção de nova semeadura',
      select_sowing_in_season:
        'Selecione as semeaduras em safra para executar o programa escolhido',
      choose_sowing_warning:
        'Se a data de semeadura for diferente da que você planejou neste programa, as eficácias podem sofrer alterações.',
      without_executed_sowing: 'Você ainda não possui semeadura executada',
      select_button:
        'Selecione o botão de "Nova semeadura" para cadastrar uma semeadura executada',
      sowing_on: 'Semeadura em',
      has_program: 'Semeadura já possui programa de fungicidas associado',
      new_sowing_warning:
        'Ao definir uma nova data de semeadura diferente da que você escolheu no programa, as eficácias e datas de aplicações podem sofrer alterações.',
      selected_fields: 'Talhões selecionados',
      select_fields: 'Selecionar talhões',
      search_field: 'Pesquise por um talhão',
      sowing_period: 'Período que você semeou',
      sowing_date: 'Data da semeadura',
      select_date: 'Selecionar data',
      sown_area: 'Área semeada',
      insert_area: 'Insira uma área',
      sowing_area_warning:
        'A área semeada não pode ser maior que a área restante do talhão',
      intend_sowing_period: 'Período que você pretende semear',
      emergence_date: 'Data de emergência',
      estimated_cycle: 'Ciclo estimado',
      type_estimated_cycle: 'Digite o ciclo estimado',
      days: 'Dias',
      fields_to_execute: 'Em quais talhões você irá executar programa?',
      chosen_program: 'Programa escolhido',
      program_score: 'Score do programa',
      program_cost: 'Custo do programa',
      total_sprayings: 'Aplicações totais',
      spraying: 'Aplicação',
      products: 'Produtos',
      dose: 'Dose',
      price: 'Preço',
      cost_per_hectare: 'Custo/ha',
      spraying_efficacy: 'Eficácia da aplicação',
      total_cost: 'Custo total',
      select_sowing: 'Selecione uma semeadura',
    },
    overwrite_sprayings_modal: {
      action: 'Executar',
      title:
        'Tem certeza que deseja executar programa nestas semeaduras? Elas já têm aplicações',
      sowing_on: 'Semeadura em',
      spraying_on: 'Aplicação em',
      warning: 'Ao confirmar, as aplicações atuais serão sobrescritas.',
    },
  },
}
