import { APIFailureWrapper } from '@/utils/mock'
import { mockFlag } from '@/utils/mock'
import {
  allPrograms,
  defaultProgramsMock,
  executedPrograms,
  getAllPrograms,
  notConfiguredPrograms,
  planningPrograms,
} from '@/modules/diseasesControl/mock/diseasesControlSeeds.js'
import {
  getPlanningAndExecutedSowings,
  planningSowings,
  getPlanningSowingsWithoutDuplicated,
} from '@/services/__mocks__/sowingsSeeds'
import { isDateBetween } from '@/utils/time'
import { fields } from '@/modules/plot/mock/fieldSeeds'
import { farms } from '@/services/__mocks__/farmSeeds'
import {
  executedSprayings,
  getPlanningSprayings,
} from '@/services/__mocks__/sprayingsSeeds'
import { insertNewSowingToList } from '@/modules/sowingsPlanning/mock/sowingsPlanningRoutes'
import { sowingsPlanningSeeds } from '@/modules/sowingsPlanning/mock/sowingsPlanningSeeds'

const diseasesControlRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/diseases-programs/:program_id/infos',
      result: ({ params }) => {
        const allPrograms = getAllPrograms()
        allPrograms.unshift(defaultProgramsMock[1])
        allPrograms.unshift(defaultProgramsMock[0])
        const currentProgram =
          allPrograms.find((program) => program.id == params.program_id) || {}
        const program = {
          name: currentProgram.name,
          estimated_sowing_date: currentProgram.estimated_sowing_date,
          expected_pressure: currentProgram.pressure,
          white_mold_control: currentProgram.white_mold_control,
          aerial_web_blight_control: currentProgram.aerial_web_blight_control,
        }
        return APIFailureWrapper({
          content: program,
          errorMessage: 'Erro na listagem de informações do programa',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/diseases-programs',
      result: ({ queryParams }) => {
        const programIds = []
        let filteredPrograms = getAllPrograms().reduce((acc, program) => {
          if (
            program.status === 'executed' &&
            program.field_id == queryParams.field_id &&
            program.variety_id == queryParams.variety_id &&
            !programIds.includes(program.id)
          ) {
            acc.push({
              id: program.id,
              name: program.name,
              sowing_id: program.sowing_id,
              variety_id: program.variety_id,
              variety_name: program.variety_name,
              crop_id: program.crop_id,
              crop_name: program.crop_name,
              spraying_quantity_suggested: program.spraying_quantity_suggested,
              pressure: program.pressure,
              aerial_web_blight_control: program.aerial_web_blight_control,
              white_mold_control: program.white_mold_control,
              estimated_sowing_date: program.estimated_sowing_date,
              date: program.date,
              sprayings: program.get_sprayings(),
              rate: program.rate,
              status: program.status,
            })
            programIds.push(program.id)
          }
          if (
            program.status === 'planning' &&
            program.name &&
            program.field_id == queryParams.field_id &&
            program.variety_id == queryParams.variety_id &&
            !programIds.includes(program.id)
          ) {
            acc.push({
              id: program.id,
              name: program.name,
              variety_id: program.variety_id,
              variety_name: program.variety_name,
              crop_id: program.crop_id,
              crop_name: program.crop_name,
              sowing_id: program.sowing_id,
              spraying_quantity_suggested: program.spraying_quantity_suggested,
              pressure: program.pressure,
              aerial_web_blight_control: program.aerial_web_blight_control,
              white_mold_control: program.white_mold_control,
              estimated_sowing_date: program.estimated_sowing_date,
              sprayings: program.get_sprayings(),
              rate: program.rate,
              status: program.status,
            })
            programIds.push(program.id)
          }
          if (queryParams.program_ids) {
            if (
              queryParams.program_ids.includes(program.id) &&
              !programIds.includes(program.id)
            ) {
              acc.push({
                id: program.id,
                name: program.name,
                variety_id: program.variety_id,
                variety_name: program.variety_name,
                crop_id: program.crop_id,
                crop_name: program.crop_name,
                sowing_id: program.sowing_id,
                spraying_quantity_suggested:
                  program.spraying_quantity_suggested,
                pressure: program.pressure,
                aerial_web_blight_control: program.aerial_web_blight_control,
                white_mold_control: program.white_mold_control,
                estimated_sowing_date: program.estimated_sowing_date,
                sprayings: program.get_sprayings(),
                rate: program.rate,
                status: program.status,
              })
              programIds.push(program.id)
            }
          }
          notConfiguredPrograms.forEach((program) => {
            if (
              !program.name &&
              program.field_id == queryParams.field_id &&
              program.variety_id == queryParams.variety_id &&
              !programIds.includes(program.id)
            ) {
              acc.push(program)
              programIds.push(program.id)
            }
          })
          return acc
        }, [])
        if (queryParams.variety_id != 3) {
          filteredPrograms.unshift(defaultProgramsMock[1])
          filteredPrograms.unshift(defaultProgramsMock[0])
        }
        if (queryParams.period) {
          if (queryParams.period === '6m') {
            filteredPrograms = filteredPrograms.filter((program) =>
              isDateBetween(program.date || program.estimated_sowing_date, 0, 6)
            )
          } else if (queryParams.period === '12m') {
            filteredPrograms = filteredPrograms.filter((program) =>
              isDateBetween(
                program.date || program.estimated_sowing_date,
                0,
                12
              )
            )
          } else if (queryParams.period === '24m') {
            filteredPrograms = filteredPrograms.filter((program) =>
              isDateBetween(
                program.date || program.estimated_sowing_date,
                0,
                24
              )
            )
          } else if (queryParams.period === 'previous_sowings') {
            filteredPrograms = filteredPrograms.filter((program) =>
              isDateBetween(
                program.date || program.estimated_sowing_date,
                120,
                0
              )
            )
          } else {
            const dates = queryParams.period.split('_')
            const startDate = dates[0]
            const endDate = dates[1]
            filteredPrograms = filteredPrograms.filter((program) =>
              isDateBetween(
                program.date || program.estimated_sowing_date,
                0,
                0,
                startDate,
                endDate
              )
            )
          }
        }
        if (queryParams.program_ids) {
          const programIds = queryParams.program_ids.split(',').map(Number)
          filteredPrograms = filteredPrograms.filter((program) => {
            return programIds.includes(program.id)
          })
          if (queryParams.field_id) {
            const allPrograms = getAllPrograms()
            const filteredFieldProgramIds = allPrograms.reduce(
              (acc, program) => {
                if (program.field_id == queryParams.field_id) {
                  acc.push(program.id)
                }
                return acc
              },
              []
            )
            filteredPrograms = filteredPrograms.filter((program) =>
              filteredFieldProgramIds.includes(program.id)
            )
          }
          if (queryParams.variety_id) {
            const allPrograms = getAllPrograms()
            const filteredVarietyProgramIds = allPrograms.reduce(
              (acc, program) => {
                if (program.variety_id == queryParams.variety_id) {
                  acc.push(program.id)
                }
                return acc
              },
              []
            )
            filteredPrograms = filteredPrograms.filter((program) =>
              filteredVarietyProgramIds.includes(program.id)
            )
          }
        }
        return APIFailureWrapper({
          content: filteredPrograms,
          errorMessage: 'Erro na listagem de programas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/programs-list',
      result: ({ queryParams }) => {
        let programs = []
        if (queryParams.farm_id && queryParams.variety_id) {
          const fieldIds = fields.reduce((acc, field) => {
            if (field.fazenda_id == queryParams.farm_id) {
              acc.push(field.id)
            }
            return acc
          }, [])
          programs = getAllPrograms().reduce((acc, program) => {
            if (
              fieldIds.includes(program.field_id) &&
              program.variety_id == queryParams.variety_id &&
              !!program.name
            ) {
              acc.push({
                id: program.id,
                name: program.name,
              })
            }
            return acc
          }, [])
        }
        return APIFailureWrapper({
          content: programs,
          errorMessage:
            'Erro na listagem de programas da fazenda e cultivar específica',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/programs-farms',
      result: () => {
        const fieldIds = getAllPrograms().reduce((acc, program) => {
          if (!!program.name && !acc.includes(program.field_id)) {
            acc.push(program.field_id)
          }
          return acc
        }, [])
        const farmIds = fields.reduce((acc, field) => {
          if (fieldIds.includes(field.id) && !acc.includes(field.id)) {
            acc.push(field.fazenda_id)
          }
          return acc
        }, [])
        const filteredFarms = farms.reduce((acc, farm) => {
          if (farmIds.includes(farm.id) && farm.enabled) {
            acc.push({
              id: farm.id,
              name: farm.name,
            })
          }
          return acc
        }, [])
        return APIFailureWrapper({
          content: filteredFarms,
          errorMessage: 'Erro na listagem de fazendas dos programas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/programs-varieties',
      result: ({ queryParams }) => {
        let varieties = []
        if (queryParams.farm_id) {
          const fieldIds = fields.reduce((acc, field) => {
            if (field.fazenda_id == queryParams.farm_id) {
              acc.push(field.id)
            }
            return acc
          }, [])
          varieties = getAllPrograms().reduce((acc, program) => {
            if (fieldIds.includes(program.field_id) && !!program.name) {
              if (program.crop_message_key == 'crop.soybean') {
                acc.unshift({
                  crop_message_key: program.crop_name,
                  id: program.variety_id,
                  name: program.variety_name,
                })
              } else {
                acc.push({
                  crop_message_key: program.crop_name,
                  id: program.variety_id,
                  name: program.variety_name,
                })
              }
            }
            return acc
          }, [])
        }
        return APIFailureWrapper({
          content: varieties,
          errorMessage: 'Erro na listagem de genéticas dos programas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/sowings-varieties',
      result: ({ queryParams }) => {
        let varieties = []
        if (queryParams.field_id) {
          varieties = getPlanningAndExecutedSowings().reduce((acc, sowing) => {
            if (sowing.field_id == queryParams.field_id) {
              acc.push({
                crop_message_key: sowing.crop_message_key,
                id: Number(sowing.variety_id),
                name: sowing.variety_name,
              })
            }
            return acc
          }, [])
        }
        return APIFailureWrapper({
          content: varieties,
          errorMessage: 'Erro na listagem de genéticas das semeaduras',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'patch',
      url: '/diseases-programs/:program_id',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        let linkToOtherSowing = false
        allPrograms.forEach((program) => {
          if (program.id == params.program_id) {
            program.aerial_web_blight_control = body.aerial_web_blight_control
            program.pressure = body.expected_pressure
            program.name = body.program_name
            program.white_mold_control = body.white_mold_control
            program.estimated_sowing_date = body.estimated_sowing_date

            if (program.status === 'planning') {
              planningSowings.forEach((sowing) => {
                if (
                  sowing.id != program.sowing_id &&
                  sowing.estimated_date == program.estimated_sowing_date
                ) {
                  // join the program into the chosen planning sowing
                  // and remove the program from the previous planning sowing
                  sowingsPlanningSeeds.forEach((sowingTask) => {
                    if (sowingTask.field_id == program.field_id) {
                      sowingTask.varieties.forEach((variety) => {
                        if (variety.variety_id == program.variety_id) {
                          variety.sowings.forEach((sowing2) => {
                            if (sowing2.id == sowing.id) {
                              sowing2.programs.push(program)
                            }
                            if (sowing2.id == program.sowing_id) {
                              const index = sowing2.programs.find(
                                (program2) => program2.id == program.id
                              )
                              sowing2.programs.splice(index, 1)
                            }
                          })
                        }
                      })
                    }
                  })
                  // join the planning sowing into the program
                  program.sowing_id = sowing.id
                  linkToOtherSowing = true
                }
              })
              // create a planning sowing, join it into program and program into the sowing
              if (!linkToOtherSowing) {
                const programSowing =
                  getPlanningSowingsWithoutDuplicated().find(
                    (sowing) => sowing.id == program.sowing_id
                  )
                const newPlanningSowingId = insertNewSowingToList({
                  cropId: program.crop_id,
                  cycle: programSowing.cycle,
                  fieldId: program.field_id,
                  program: program,
                  varietyId: program.variety_id,
                  varName: program.variety_name,
                  sowingDate: program.estimated_sowing_date,
                })
                // remove the program from the previous planning sowing
                sowingsPlanningSeeds.forEach((sowingTask) => {
                  if (sowingTask.field_id == program.field_id) {
                    sowingTask.varieties.forEach((variety) => {
                      if (variety.variety_id == program.variety_id) {
                        variety.sowings.forEach((sowing2) => {
                          if (sowing2.id == program.sowing_id) {
                            const index = sowing2.programs.find(
                              (program2) => program2.id == program.id
                            )
                            sowing2.programs.splice(index, 1)
                          }
                        })
                      }
                    })
                  }
                })
                program.sowing_id = newPlanningSowingId
                planningPrograms.push(program)
              }
            }
          }
        })
        return APIFailureWrapper({
          content: params.program_id,
          errorMessage: 'Erro na edição de programa',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/diseases-programs',
      result: ({ requestBody }) => {
        const body = JSON.parse(requestBody)
        const newId = getAllPrograms().length + 1
        const newProgram = {
          aerial_web_blight_control: body.aerial_web_blight_control,
          crop_id: body.crop.id,
          crop_name: body.crop.message_key,
          date: '',
          estimated_sowing_date: body.estimated_sowing_date,
          field_id: body.field_id,
          id: newId,
          name: body.program_name,
          phitosanitary_category_name: 'Disease',
          phitosanitary_category_message_key: 'phytosanitary_category.disease',
          pressure: body.expected_pressure,
          rate: Math.random() * 5,
          sowing_id: null,
          spraying_quantity_suggested: [3, 4],
          sprayings: [],
          get_sprayings: () =>
            getPlanningSprayings().filter(
              (spraying) => spraying.program_id == newId
            ),
          status: 'planning',
          variety_id: body.variety.id,
          variety_name: body.variety.name,
          white_mold_control: body.white_mold_control,
        }
        const sowingAtDate = getPlanningSowingsWithoutDuplicated().find(
          (sowing) => {
            return (
              sowing.field_id == body.field_id &&
              sowing.variety_id == body.variety.id &&
              sowing.estimated_date == body.estimated_sowing_date
            )
          }
        )

        let newPlanningSowingId = null
        if (sowingAtDate) {
          sowingsPlanningSeeds.forEach((sowingTask) => {
            if (sowingTask.field_id == body.field_id) {
              sowingTask.varieties.forEach((variety) => {
                if (variety.variety_id == body.variety.id) {
                  variety.sowings.forEach((sowing) => {
                    if (sowing.id == sowingAtDate.id) {
                      sowing.programs.push(newProgram)
                    }
                  })
                }
              })
            }
          })
        } else {
          newPlanningSowingId = insertNewSowingToList({
            cropId: body.crop.id,
            cycle: 120,
            fieldId: body.field_id,
            program: newProgram,
            varietyId: body.variety.id,
            varName: body.variety.name,
            sowingDate: body.estimated_sowing_date,
          })
        }
        newProgram.sowing_id = newPlanningSowingId || sowingAtDate.id
        planningPrograms.push(newProgram)
        return APIFailureWrapper({
          content: newId,
          errorMessage: 'Erro na criação de programa',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/diseases-programs/:program_id/copy',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        const allPrograms = getAllPrograms()
        const currentProgram = {
          ...allPrograms.find((program) => program.id == params.program_id),
        }
        currentProgram.id = allPrograms.length + 1
        for (let fieldId of body.field_ids) {
          currentProgram.field_id = fieldId
          const sameDateSowing = getPlanningSowingsWithoutDuplicated().find(
            (sowing) =>
              sowing.estimated_date == currentProgram.estimated_sowing_date &&
              sowing.field_id == currentProgram.field_id &&
              sowing.variety_id == currentProgram.variety_id
          )
          if (sameDateSowing && Object.keys(sameDateSowing).length) {
            currentProgram.sowing_id = sameDateSowing.id
            planningPrograms.push(currentProgram)
            sowingsPlanningSeeds.forEach((sowingTask) => {
              if (sowingTask.field_id == sameDateSowing.field_id) {
                sowingTask.varieties.forEach((variety) => {
                  if (variety.variety_id == sameDateSowing.variety_id) {
                    variety.sowings.forEach((sowing) => {
                      if (sowing.id == sameDateSowing.id) {
                        sowing.programs.push(currentProgram)
                      }
                    })
                  }
                })
              }
            })
          } else {
            const field = fields.find((field) => field.id == fieldId)
            const newPlanningSowing = {
              id: planningSowings.length + 10,
              farm_id: field.fazenda_id,
              field_id: fieldId,
              variety_id: currentProgram.variety_id,
              variety_name: currentProgram.variety_name,
              crop_id: currentProgram.crop_id,
              crop_message_key: currentProgram.crop_name,
              cycle: 120,
              sowing_status: 'planning',
              harvest_status: null,
              growth_stage: null,
              estimated_date: currentProgram.estimated_sowing_date,
              date: null,
              emergence_date: null,
              harvested_at: null,
              area: null,
              seed_class_id: null,
              germinative_power: null,
              seed_vigor: null,
              seeder_system_id: null,
              line_spacing: null,
              population_lines: null,
              sown_quantity: null,
              operating_speed: null,
              origin: null,
              seed_treatment: null,
              inoculant: null,
              harvested: null,
              harvest_quantity: null,
            }
            currentProgram.sowing_id = newPlanningSowing.id
            planningSowings.push(newPlanningSowing)
            planningPrograms.push(currentProgram)
          }
        }
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação/duplicação do programa',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/diseases-programs/:program_id/execute',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        const allPrograms = getAllPrograms()
        for (let sowingId of body.sowing_ids) {
          const currentSowing = {
            ...getPlanningAndExecutedSowings().find(
              (sowing) => sowing.id == sowingId
            ),
          }
          const currentProgram = {
            ...allPrograms.find((program) => program.id == params.program_id),
          }
          executedSprayings.forEach((spraying) => {
            if (spraying.sowing_id == sowingId) {
              spraying.sowing_id = null
            }
          })
          currentProgram.sprayings.forEach((spraying) => {
            const newSpraying = {
              ...spraying,
              sowing_id: sowingId,
            }
            executedSprayings.push(newSpraying)
          })

          currentProgram.id = allPrograms.length + 1
          currentProgram.status = 'executed'
          currentProgram.field_id = currentSowing.field_id
          currentProgram.date = currentSowing.date
          currentProgram.estimated_sowing_date = currentSowing.estimated_date
          currentProgram.rate = Math.random() * 5
          currentProgram.sowing_id = currentSowing.id
          currentProgram.variety_id = currentSowing.variety_id
          currentProgram.variety_name = currentSowing.variety_name
          executedPrograms.push(currentProgram)
        }
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na execução do programa em safra',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/delete-program/:program_id',
      result: ({ params }) => {
        let currentIndex = null
        const allPrograms = [
          notConfiguredPrograms,
          planningPrograms,
          executedPrograms,
        ]
        allPrograms.forEach((programsArray) => {
          programsArray.forEach((program, index) => {
            if (program.id == params.program_id) {
              currentIndex = index
            }
          })
          if (currentIndex != null) {
            programsArray.splice(currentIndex, 1)
            return APIFailureWrapper({
              content: null,
              errorMessage: 'Erro na deleção do programa',
            })
          }
        })
      },
    },
    'on'
  ),
]

export { diseasesControlRoutes }
