export default {
  DiseasesControl: {
    planning_fungicide: 'visitedPage_planningFungicide',
    filtered_by_field: 'usedFeature_filteredByField',
    filtered_by_crop: 'usedFeature_filteredByCrop',
    filtered_by_period: 'usedFeature_filteredByPeriod',
    new_program: 'clickedButton_newProgram',
    finish_new_program: 'clickedButton_finishNewProgram',
    configure_program: 'clickedButton_configureProgram',
    execute_in_season: 'clickedButton_executeInSeason',
    create_spraying: 'clickedButton_createApplication',
    finish_copy: 'clickedButton_finishCopy',
    associate_sowing: 'clickedButton_associateSowing',
    new_sowing_date: 'clickedButton_newSowingDate',
    finish_add_sowing_in: 'clickedButton_finishAddSowingIn',
  },
}
