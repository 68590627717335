const Module = () =>
  import(
    /* webpackChunkName: "diseases-control-module" */ '@/modules/diseasesControl/Module.vue'
  )
const DiseasesControlHome = () =>
  import(
    /* webpackChunkName: "diseases-control-module" */ '@/modules/diseasesControl/views/DiseasesControlHome.vue'
  )
const CompareDiseasesControlHome = () =>
  import(
    /* webpackChunkName: "diseases-control-module" */ '@/modules/diseasesControl/views/CompareDiseasesControlHome.vue'
  )

const routes = [
  {
    path: '/diseases_control',
    component: Module,
    children: [
      {
        path: '',
        component: DiseasesControlHome,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'compare',
        component: CompareDiseasesControlHome,
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
