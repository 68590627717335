export default {
  DiseasesControl: {
    header: {
      planning_title: 'Planificación de Fungicidas',
      compare_programs: 'Comparar programas',
      add_program: 'Nuevo programa',
      filter_by: 'Filtrado por',
      select_field: 'Seleccione lote',
      search_field: 'Busca el lote',
      select_variety: 'Seleccione genética',
      search_variety: 'Busca la genética',
      period: 'Período',
    },
    home: {
      without_field_text:
        'Registra tus lotes para poder planificar tus fungicidas',
      without_field_title: 'Aún no tienes lotes registrados',
      without_sowing_text:
        'El cultivo, la genética y la fecha estimada son necesarias para que nuestro algoritmo realice cálculos de eficacia basados ​​en <b>tu realidad</b>.',
      without_sowing_title: 'Aún no tienes siembras previstas para este lote',
      without_program_text:
        'Establecer la fecha de siembra y la presión de la enfermedad',
      without_program_title: '1º Paso: configura tu primer programa',
    },
    compare_home: {
      farm: 'Hacienda',
      search_farm: 'Busca la hacienda',
      variety: 'Variedad',
      search_variety: 'Busca la variedad',
      program: 'Programa',
      search_program: 'Busca el programa',
      add: 'Agregar',
    },
    add_program_modal: {
      create_program: 'Crear programa',
      save: 'Guardar',
      new_program: 'Nuevo programa',
      edit_program: 'Editar programa',
      program_name: 'Nombre del programa',
      insert_program_name: 'Introduzca un nombre para el programa',
      estimated_sowing_hint: 'Periodo que pretende sembrar',
      estimated_sowing_date: 'Fecha estimada de siembra',
      select_date: 'Seleccione la fecha',
      expected_pressure: 'Presión esperada',
      high: 'Alto',
      medium: 'Promedio',
      low: 'Bajo',
      diseases: 'Enfermedades',
      diseases_description:
        'Marcando "Sí" para Moho Blanco y/o Tizón Foliar, podemos considerar aplicaciones específicas con comportamiento específico en relación a las demás.',
      white_mold_control: '¿Te gustaría controlar a Moho Blanco?',
      aerial_web_blight_control: '¿Te gustaría controlar a Tizón Foliar?',
      no: 'No',
      yes: 'Sí',
      successful_program_created: 'Programa registrado exitosamente',
      date_not_recommended: 'Fecha no recomendada para tu región',
      registered_sowing_date: 'Fecha de siembra ya registrada',
      date_selection_warning:
        'Al elegir una fecha con una planificación de siembra, éste estará asociado al programa. Si la fecha no tiene planificación de siembra, se creará una nueva planificación asociada al programa.',
      edit_date_selection_warning:
        'En ambos casos se desvinculará la planificación de siembra vigente del programa.',
    },
    program_item: {
      spraying: 'aplicación',
      sprayings: 'aplicaciones',
      sowing_on: 'Siembra en',
      pressure: 'presión',
      program_cost: 'Costo del programa',
      waiting_first_spraying: 'Esperando 1ª aplicación',
      execute_in_season: 'Realizar en zafra',
      suggested_sprayings_quantity: 'Número de aplicaciones sugeridas',
      to: 'a',
      white_mold_spraying: '1 para moho blanco',
      insert_spraying: 'Insertar {0} aplicación',
      configure_program: 'Configurar programa',
      not_configured: 'No configurado',
      high: 'Alto',
      medium: 'Promedia',
      low: 'Bajo',
      edit: 'Editar',
      copy_to: 'Copiar a',
      duplicate: 'Duplicado',
      compare_program: 'Comparar programa',
      print: 'Imprimir',
      delete: 'Borrar',
      select_field_description:
        'Seleccione los programas que desea realizar una copia de esta siembra',
      copy_program: 'Copiar programa',
      successfully_copy_program:
        'Programa copiado para los lotes seleccionados',
      successfully_duplicate_program: 'Programa duplicado para el lote',
      delete_program_title: '¿Estás seguro de que deseas eliminar el programa?',
      delete_program_text:
        'Todos los datos contenidos en este programa se perderán y esta acción no se puede deshacer',
    },
    program_spraying_item: {
      spraying: 'aplicación',
      products: 'Productos',
      more_products: 'Más {0} productos',
      efficacies: 'Eficiencias',
      edit: 'Editar',
      delete: 'Borrar',
    },
    execute_program_modal: {
      execute_program: 'Realizar programa',
      execute_in_season: 'Realizar programa en safra',
      fields_to_execute_warning:
        'Seleccione los lotes que desea realizar el programa',
      sowings_to_execute:
        'Seleccione las siembras que desea realizar el programa',
      choose_sowing: 'Eligir siembra',
      new_sowing: 'Nueva semeadura',
      loading: 'Cargando',
      disease: 'fungicida',
      program_of: 'Programa de',
      program_objective: '¿Qué quieres hacer con el programa?',
      choose_sowing_info:
        'En esta opción <b>elegir siembra</b> asociará el programa elegido a la siembra ya realizada durante la zafra. Si no tienes ninguno, elige la opción de nueva siembra.',
      select_sowing_in_season:
        'Seleccionar las siembras en zafra para ejecutar el programa elegido',
      choose_sowing_warning:
        'Si la fecha de siembra es diferente a la planificada en este programa, las eficacias pueden cambiar.',
      without_executed_sowing: 'Aún no has siembra realizada',
      select_button:
        'Seleccione el botón "Nueva siembra" para registrar una siembra realizada',
      sowing_on: 'Siembra en',
      has_program:
        'La siembra ya cuenta con un programa de fungicidas asociado',
      new_sowing_warning:
        'Al definir una nueva fecha de siembra diferente a la que usted eligió en el programa, las fechas de efectividad y aplicación pueden cambiar.',
      selected_fields: 'Lotes seleccionados',
      select_fields: 'Seleccionar lotes',
      search_field: 'Buscar un lote',
      sowing_period: 'Período que sembraste',
      sowing_date: 'Fecha de la siembra',
      select_date: 'Seleccionar fecha',
      sown_area: 'Área sembrada',
      insert_area: 'Ingrese a un área',
      sowing_area_warning:
        'El área sembrada no puede ser mayor que el área restante del lote',
      intend_sowing_period: 'Periodo que pretende sembrar',
      emergence_date: 'Fecha de emergencia',
      estimated_cycle: 'Ciclo estimado',
      type_estimated_cycle: 'Ingrese el ciclo estimado',
      days: 'Días',
      fields_to_execute: '¿En qué lotes realizarás el programa?',
      chosen_program: 'Programa elegido',
      program_score: 'Puntuación del programa',
      program_cost: 'Costo del programa',
      total_sprayings: 'Aplicaciones totales',
      spraying: 'Aplicación',
      products: 'Productos',
      dose: 'Dosis',
      price: 'Precio',
      cost_per_hectare: 'Costo/ha',
      spraying_efficacy: 'Eficiencia de la aplicación',
      total_cost: 'Costo total',
      select_sowing: 'Seleccione una siembra',
    },
    overwrite_sprayings_modal: {
      action: 'Realizar',
      title:
        '¿Está seguro de querer realizar el programa en estas siembras? Ya tienen aplicaciones',
      sowing_on: 'Siembra en',
      spraying_on: 'Aplicación en',
      warning:
        'Tras la confirmación, las aplicaciones actuales se sobrescribirán.',
    },
  },
}
