import { catalogFungicides } from '@/modules/catalog/mock/catalogSeeds'
import { getCustomDate } from '@/utils/time'
import {
  getRandomEfficacies,
  seasonDiseases,
} from '@/services/__mocks__/diseasesSeeds'
import {
  sowingsForDefaultPrograms,
  getExecutedSowings,
  getPlanningSowingsWithoutDuplicated,
  planningSowings,
} from '@/services/__mocks__/sowingsSeeds'
import {
  executedSprayings,
  getExecutedSprayings,
  getPlanningSprayings,
  planningSprayings,
} from '@/services/__mocks__/sprayingsSeeds'

const notConfiguredPrograms = [
  {
    id: 1000,
    sowing_id: null,
    field_id: planningSowings[1].field_id,
    variety_id: planningSowings[1].variety_id,
    variety_name: planningSowings[1].variety_name,
    crop_id: planningSowings[1].crop_id,
    crop_name: planningSowings[1].crop_message_key,
    name: '',
    spraying_quantity_suggested: [],
    pressure: '',
    aerial_web_blight_control: 'no',
    white_mold_control: 'no',
    estimated_sowing_date: planningSowings[1].estimated_date,
    sprayings: [],
    status: 'planning',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
  },
  {
    id: 1001,
    sowing_id: null,
    field_id: planningSowings[3].field_id,
    variety_id: planningSowings[3].variety_id,
    variety_name: planningSowings[3].variety_name,
    crop_id: planningSowings[3].crop_id,
    crop_name: planningSowings[3].crop_message_key,
    name: '',
    spraying_quantity_suggested: [],
    pressure: '',
    aerial_web_blight_control: 'no',
    white_mold_control: 'no',
    estimated_sowing_date: planningSowings[3].estimated_date,
    sprayings: [],
    status: 'planning',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
  },
]

// planning
// variety1PlanningSowings = [101, 104]
const variety1PlanningSowings = getPlanningSowingsWithoutDuplicated().filter(
  (sowing) => sowing.variety_id == 1
)
// variety6PlanningSowings = [103]
const variety7PlanningSowings = getPlanningSowingsWithoutDuplicated().filter(
  (sowing) => sowing.variety_id == 7
)

const planningPrograms = [
  {
    id: 1,
    sowing_id: variety1PlanningSowings[0].id,
    field_id: variety1PlanningSowings[0].field_id,
    variety_id: variety1PlanningSowings[0].variety_id,
    variety_name: variety1PlanningSowings[0].variety_name,
    crop_id: variety1PlanningSowings[0].crop_id,
    crop_name: variety1PlanningSowings[0].crop_message_key,
    estimated_sowing_date: variety1PlanningSowings[0].estimated_date,
    date: '',
    name: `Planejando doenças - ${variety1PlanningSowings[0].variety_name}`,
    spraying_quantity_suggested: [3, 4],
    pressure: 'medium',
    aerial_web_blight_control: 'no',
    white_mold_control: 'no',
    crop_zone_id: 1,
    sprayings: planningSprayings.filter((spraying) => spraying.program_id == 1),
    rate: Math.random() * 5,
    status: 'planning',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
    get_sprayings: () =>
      getPlanningSprayings().filter((spraying) => spraying.program_id == 1),
  },
  {
    id: 2,
    sowing_id: variety7PlanningSowings[0].id,
    field_id: variety7PlanningSowings[0].field_id,
    variety_id: variety7PlanningSowings[0].variety_id,
    variety_name: variety7PlanningSowings[0].variety_name,
    crop_id: variety7PlanningSowings[0].crop_id,
    crop_name: variety7PlanningSowings[0].crop_message_key,
    estimated_sowing_date: variety7PlanningSowings[0].estimated_date,
    date: '',
    name: `Planejando doenças - ${variety7PlanningSowings[0].variety_name}`,
    spraying_quantity_suggested: [2],
    pressure: 'high',
    aerial_web_blight_control: 'yes',
    white_mold_control: 'no',
    crop_zone_id: 1,
    sprayings: planningSprayings.filter((spraying) => spraying.program_id == 2),
    rate: Math.random() * 5,
    status: 'planning',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
    get_sprayings: () =>
      getPlanningSprayings().filter((spraying) => spraying.program_id == 2),
  },
]

// executed
// variety1ExecutedSowings = [1, 100, 102]
const variety1ExecutedSowings = getExecutedSowings().filter(
  (sowing) => sowing.variety_id == 1
)
// variety2ExecutedSowings = [2, 4]
const variety2ExecutedSowings = getExecutedSowings().filter(
  (sowing) => sowing.variety_id == 2
)
// variety8ExecutedSowings = [5]
const variety8ExecutedSowings = getExecutedSowings().filter(
  (sowing) => sowing.variety_id == 8
)

const executedPrograms = [
  {
    id: 3,
    sowing_id: variety1ExecutedSowings[0].id,
    field_id: variety1ExecutedSowings[0].field_id,
    variety_id: variety1ExecutedSowings[0].variety_id,
    variety_name: variety1ExecutedSowings[0].variety_name,
    crop_id: variety1ExecutedSowings[0].crop_id,
    crop_name: variety1ExecutedSowings[0].crop_message_key,
    estimated_sowing_date: variety1ExecutedSowings[0].estimated_date,
    date: variety1ExecutedSowings[0].date,
    name: `Tratar doenças - ${variety1ExecutedSowings[0].variety_name}`,
    spraying_quantity_suggested: [3],
    pressure: 'low',
    aerial_web_blight_control: 'yes',
    white_mold_control: 'yes',
    crop_zone_id: 1,
    sprayings: executedSprayings.filter((spraying) => spraying.program_id == 3),
    rate: Math.random() * 5,
    status: 'executed',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
    get_sprayings: () =>
      getExecutedSprayings().filter((spraying) => spraying.program_id == 3),
  },
  {
    id: 4,
    sowing_id: variety1ExecutedSowings[1].id,
    field_id: variety1ExecutedSowings[1].field_id,
    variety_id: variety1ExecutedSowings[1].variety_id,
    variety_name: variety1ExecutedSowings[1].variety_name,
    crop_id: variety1ExecutedSowings[1].crop_id,
    crop_name: variety1ExecutedSowings[1].crop_message_key,
    estimated_sowing_date: variety1ExecutedSowings[1].estimated_date,
    date: variety1ExecutedSowings[1].date,
    name: `Segunda tratativa de doenças - ${variety1ExecutedSowings[1].variety_name}`,
    spraying_quantity_suggested: [2],
    pressure: 'medium',
    aerial_web_blight_control: 'no',
    white_mold_control: 'yes',
    crop_zone_id: 1,
    sprayings: executedSprayings.filter((spraying) => spraying.program_id == 4),
    rate: Math.random() * 5,
    status: 'executed',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
    get_sprayings: () =>
      getExecutedSprayings().filter((spraying) => spraying.program_id == 4),
  },
  {
    id: 6,
    sowing_id: variety2ExecutedSowings[0].id,
    field_id: variety2ExecutedSowings[0].field_id,
    variety_id: variety2ExecutedSowings[0].variety_id,
    variety_name: variety2ExecutedSowings[0].variety_name,
    crop_id: variety2ExecutedSowings[0].crop_id,
    crop_name: variety2ExecutedSowings[0].crop_message_key,
    estimated_sowing_date: variety2ExecutedSowings[0].estimated_date,
    date: variety2ExecutedSowings[0].date,
    name: `Tratar doenças - ${variety2ExecutedSowings[0].variety_name}`,
    spraying_quantity_suggested: [4],
    pressure: 'high',
    aerial_web_blight_control: 'no',
    white_mold_control: 'no',
    crop_zone_id: 1,
    sprayings: executedSprayings.filter((spraying) => spraying.program_id == 6),
    rate: Math.random() * 5,
    status: 'executed',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
    get_sprayings: () =>
      getExecutedSprayings().filter((spraying) => spraying.program_id == 6),
  },
  {
    id: 7,
    sowing_id: variety2ExecutedSowings[1].id,
    field_id: variety2ExecutedSowings[1].field_id,
    variety_id: variety2ExecutedSowings[1].variety_id,
    variety_name: variety2ExecutedSowings[1].variety_name,
    crop_id: variety2ExecutedSowings[1].crop_id,
    crop_name: variety2ExecutedSowings[1].crop_message_key,
    estimated_sowing_date: variety2ExecutedSowings[1].estimated_date,
    date: variety2ExecutedSowings[1].date,
    name: `Segunda tentativa de tratar doenças - ${variety2ExecutedSowings[1].variety_name}`,
    spraying_quantity_suggested: [2],
    pressure: 'medium',
    aerial_web_blight_control: 'no',
    white_mold_control: 'yes',
    crop_zone_id: 1,
    sprayings: executedSprayings.filter((spraying) => spraying.program_id == 7),
    rate: Math.random() * 5,
    status: 'executed',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
    get_sprayings: () =>
      getExecutedSprayings().filter((spraying) => spraying.program_id == 7),
  },
  {
    id: 8,
    sowing_id: variety8ExecutedSowings[0].id,
    field_id: variety8ExecutedSowings[0].field_id,
    variety_id: variety8ExecutedSowings[0].variety_id,
    variety_name: variety8ExecutedSowings[0].variety_name,
    crop_id: variety8ExecutedSowings[0].crop_id,
    crop_name: variety8ExecutedSowings[0].crop_message_key,
    estimated_sowing_date: variety8ExecutedSowings[0].estimated_date,
    date: variety8ExecutedSowings[0].date,
    name: `Tratar doenças - ${variety8ExecutedSowings[0].variety_name}`,
    spraying_quantity_suggested: [4, 5],
    pressure: 'high',
    aerial_web_blight_control: 'yes',
    white_mold_control: 'no',
    crop_zone_id: 1,
    sprayings: executedSprayings.filter((spraying) => spraying.program_id == 8),
    rate: Math.random() * 5,
    status: 'executed',
    phitosanitary_category_name: 'Disease',
    phitosanitary_category_message_key: 'phytosanitary_category.disease',
    get_sprayings: () =>
      getExecutedSprayings().filter((spraying) => spraying.program_id == 8),
  },
]

const defaultProgramsMock = [
  {
    id: 100,
    sowing_id: sowingsForDefaultPrograms[0].id,
    field_id: sowingsForDefaultPrograms[0].field_id,
    variety_id: sowingsForDefaultPrograms[0].variety_id,
    variety_name: sowingsForDefaultPrograms[0].variety_name,
    crop_id: sowingsForDefaultPrograms[0].crop_id,
    crop_name: sowingsForDefaultPrograms[0].crop_message_key,
    estimated_sowing_date: '',
    date: sowingsForDefaultPrograms[0].date,
    name: 'Programa padrão do mock para todas semeaduras',
    spraying_quantity_suggested: [2],
    pressure: 'high',
    aerial_web_blight_control: 'no',
    white_mold_control: 'no',
    rate: 4.5,
    status: 'executed',
    sprayings: [
      {
        id: 1,
        ideal_date: getCustomDate(10),
        type: 'disease',
        spraying_order: 1,
        main_disease_id: 1,
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
        ]),
        products: [
          {
            id: catalogFungicides[0].pesticide_id,
            name: catalogFungicides[0].pesticide,
            dose_per_hectare: 1.23,
            price_per_liter: 20,
          },
          {
            id: catalogFungicides[1].pesticide_id,
            name: catalogFungicides[1].pesticide,
            dose_per_hectare: 2.4,
            price_per_liter: 10,
          },
          {
            id: catalogFungicides[2].pesticide_id,
            name: catalogFungicides[2].pesticide,
            dose_per_hectare: 4.6,
            price_per_liter: 5,
          },
          {
            id: catalogFungicides[3].pesticide_id,
            name: catalogFungicides[3].pesticide,
            dose_per_hectare: 2.8,
            price_per_liter: 7.6,
          },
        ],
      },
      {
        id: 2,
        ideal_date: getCustomDate(20),
        type: 'disease',
        spraying_order: 2,
        main_disease_id: 1,
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
        ]),
        products: [
          {
            id: catalogFungicides[4].pesticide_id,
            name: catalogFungicides[4].pesticide,
            dose_per_hectare: 23,
            price_per_liter: 1.2,
          },
          {
            id: catalogFungicides[5].pesticide_id,
            name: catalogFungicides[5].pesticide,
            dose_per_hectare: 7.5,
            price_per_liter: 15,
          },
        ],
      },
    ],
  },
  {
    id: 101,
    sowing_id: sowingsForDefaultPrograms[1].id,
    field_id: sowingsForDefaultPrograms[1].field_id,
    variety_id: sowingsForDefaultPrograms[1].variety_id,
    variety_name: sowingsForDefaultPrograms[1].variety_name,
    crop_id: sowingsForDefaultPrograms[1].crop_id,
    crop_name: sowingsForDefaultPrograms[1].crop_message_key,
    estimated_sowing_date: '',
    date: sowingsForDefaultPrograms[1].date,
    name: 'Segundo programa padrão do mock para todas semeaduras',
    spraying_quantity_suggested: [2, 3],
    pressure: 'medium',
    aerial_web_blight_control: 'yes',
    white_mold_control: 'yes',
    rate: 3.7,
    status: 'executed',
    sprayings: [
      {
        id: 1,
        ideal_date: getCustomDate(20),
        type: 'disease',
        spraying_order: 1,
        main_disease_id: 1,
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
        products: [
          {
            id: catalogFungicides[0].pesticide_id,
            name: catalogFungicides[0].pesticide,
            dose_per_hectare: 1.23,
            price_per_liter: 20,
          },
          {
            id: catalogFungicides[1].pesticide_id,
            name: catalogFungicides[1].pesticide,
            dose_per_hectare: 2.4,
            price_per_liter: 10,
          },
        ],
      },
    ],
  },
]

const allPrograms = planningPrograms
allPrograms.push(executedPrograms)
allPrograms.push(defaultProgramsMock)

const getExecutedPrograms = () => [...executedPrograms]
const getPlanningPrograms = () => [...planningPrograms]
const getAllPrograms = () => [
  ...notConfiguredPrograms,
  ...getPlanningPrograms(),
  ...getExecutedPrograms(),
]

export {
  allPrograms,
  defaultProgramsMock,
  executedPrograms,
  getAllPrograms,
  getExecutedPrograms,
  getPlanningPrograms,
  notConfiguredPrograms,
  planningPrograms,
}
