export default {
  DiseasesControl: {
    header: {
      planning_title: 'Fungicides Planning',
      compare_programs: 'Compare programs',
      add_program: 'New program',
      filter_by: 'Filter by',
      select_field: 'Select field',
      search_field: 'Search the field',
      select_variety: 'Select genetic',
      search_variety: 'Search the genetic',
      period: 'Period',
    },
    home: {
      without_field_text:
        'Register your fields to be able to plan your fungicides',
      without_field_title: "You don't have registered fields yet",
      without_sowing_text:
        'The crop, genetic and estimated date are required for the algorithm to perform the efficacy calculations based on <b>your reality</b>',
      without_sowing_title:
        "You still don't have any planned sowing for this field",
      without_program_text: 'Set the sowing date and disease pressure',
      without_program_title: '1º Step: configure your first program',
    },
    compare_home: {
      farm: 'Farm',
      search_farm: 'Search the farm',
      variety: 'Variety',
      search_variety: 'Search the variety',
      program: 'Program',
      search_program: 'Search the program',
      add: 'Add',
    },
    add_program_modal: {
      create_program: 'Create program',
      save: 'Save',
      new_program: 'New program',
      edit_program: 'Edit program',
      program_name: 'Program name',
      insert_program_name: 'Insert a name for the program',
      estimated_sowing_hint: 'Period you intend to sow',
      estimated_sowing_date: 'Estimated sowing date',
      select_date: 'Select date',
      expected_pressure: 'Expected pressure',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      diseases: 'Diseases',
      diseases_description:
        'By marking "Yes" for White Mold and/or Aerial Web Blight, we can consider specific applications with specific behavior in relation to the others.',
      white_mold_control: 'Would you like to control White Mold?',
      aerial_web_blight_control: 'Would you like to control Aerial Web Blight?',
      no: 'No',
      yes: 'Yes',
      successful_program_created: 'Program succesfully created',
      date_not_recommended: 'Date not recommended for your region',
      registered_sowing_date: 'Sowing date already registered',
      date_selection_warning:
        'When choosing a date with a planning sowing, it will be associated with the program. If the date does not have a planning sowing, a new planning will be created and associated with the program.',
      edit_date_selection_warning:
        'In both cases, the current planning sowing for the program will be unlinked.',
    },
    program_item: {
      spraying: 'spraying',
      sprayings: 'sprayings',
      sowing_on: 'Sowing on',
      pressure: 'pressure',
      program_cost: 'Program cost',
      waiting_first_spraying: 'Waiting 1ˢᵗ spraying',
      execute_in_season: 'Execute in season',
      suggested_sprayings_quantity: 'Suggested sprayings quantity',
      to: 'to',
      white_mold_spraying: '1 for white mold',
      insert_spraying: 'Insert {0} spraying',
      configure_program: 'Configure program',
      not_configured: 'Not configured',
      high: 'Hight',
      medium: 'Medium',
      low: 'Low',
      edit: 'Edit',
      copy_to: 'Copy to',
      duplicate: 'Duplicate',
      compare_program: 'Compare program',
      print: 'Print',
      delete: 'Delete',
      select_field_description:
        'Select the programs you want to make a copy of this sowing',
      copy_program: 'Copy program',
      successfully_copy_program: 'Program copied to the selected fields',
      successfully_duplicate_program: 'Program duplicated to the field',
      delete_program_title: 'Are you sure you want to delete the program?',
      delete_program_text:
        'All data contained in this program will be lost and this action cannot be undone',
    },
    program_spraying_item: {
      spraying: 'spraying',
      products: 'Products',
      more_products: '{0} more products',
      efficacies: 'Efficacies',
      edit: 'Edit',
      delete: 'Delete',
    },
    execute_program_modal: {
      execute_program: 'Execute program',
      execute_in_season: 'Execute program in season',
      fields_to_execute_warning:
        'Select the fields you wish to execute the program',
      sowings_to_execute: 'Select the sowings you wish to execute the program',
      choose_sowing: 'Choose sowing',
      new_sowing: 'New sowing',
      loading: 'Loading',
      disease: 'fungicide',
      program_of: 'Program of',
      program_objective: 'What do you wish to do with the program?',
      choose_sowing_info:
        "In this <b>choose sowing</b> option, you will associate the chosen program with executed sowings during the harvest. If you don't have any, choose the new sowing option",
      select_sowing_in_season:
        'Select the sowings in season to execute the chosen program',
      choose_sowing_warning:
        'If the sowing date is different from what you planned in this program, the efficacies may change.',
      without_executed_sowing: "You still don't have an executed sowing",
      select_button:
        'Select the "New sowing" button to create an executed sowing',
      sowing_on: 'Sowing on',
      has_program: 'Sowing already has an associated fungicide program',
      new_sowing_warning:
        'When defining a new sowing date different from the one you chose in the program, the efficacies and program dates may change.',
      selected_fields: 'Selected fields',
      select_fields: 'Select fields',
      search_field: 'Search for a field',
      sowing_period: 'Period you sowed',
      sowing_date: 'Sowing date',
      select_date: 'Select the date',
      sown_area: 'Sown area',
      insert_area: 'Insert an area',
      sowing_area_warning:
        "The sown area can't be larger than the remaining field area",
      intend_sowing_period: 'Period you intend to sow',
      emergence_date: 'Estimated date',
      estimated_cycle: 'Estimated cycle',
      type_estimated_cycle: 'Type the estimated cycle',
      days: 'Days',
      fields_to_execute: 'In which field will you execute the program?',
      chosen_program: 'Chosen program',
      program_score: 'Program score',
      program_cost: 'Program cost',
      total_sprayings: 'Total sprayings',
      spraying: 'Spraying',
      products: 'Products',
      dose: 'Dose',
      price: 'Price',
      cost_per_hectare: 'Cost/ha',
      spraying_efficacy: 'Spraying efficacy',
      total_cost: 'Total cost',
      select_sowing: 'Select a sowing',
    },
    overwrite_sprayings_modal: {
      action: 'Execute',
      title:
        'Are you sure you want to execute this program in these sowings? They already have sprayings',
      sowing_on: 'Sowing on',
      spraying_on: 'Spraying on',
      warning: 'Upon confirmation, current sprayings will be overwritten.',
    },
  },
}
